// src/services/Scheduling.service.js

import axios from 'axios';

export default {
  created() {
    // handle api call errors
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                    GET BY PARAMS
  // *****************************************************/
  async getEvents(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_scheduling/events/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  async createNotification(data, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_notifications/notifications/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async createEvent(data, accessToken) {
    // uuid gets added inside api call

    let res = await axios({
      method: 'POST',
      url: '/service_scheduling/events/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data; // must return new eventids
  },

  // *****************************************************/
  //                      UPDATE
  // *****************************************************/
  async updateForeman(workorder, accessToken) {
    console.log('workorder: ', workorder);

    if (
      workorder &&
      workorder.event_uuids &&
      workorder.event_uuids.length > 0
    ) {
      let res = await axios({
        url: '/service_scheduling/events/updateForeman',
        method: 'PUT',
        data: {
          event_uuids: workorder.event_uuids,
          foreman_uuid: workorder.user_uuid,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (res && res.data) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  async updateEvent(uuid, data, accessToken) {
    console.log('event_uuid: ', uuid);
    let res = await axios({
      url: '/service_scheduling/events/' + uuid,
      method: 'PUT',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  // *****************************************************/
  //                       DELETE
  // *****************************************************/
  async deleteWorkOrderEvent(uuid, accessToken) {
    let res = await axios({
      method: 'DELETE',
      url: '/service_scheduling/events/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },

  async deleteWorkOrderEvents(wo_event_uuids, accessToken) {
    let res = await axios({
      method: 'DELETE',
      url: '/service_scheduling/events/deleteAll',
      params: {
        event_uuids: wo_event_uuids,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (res && res.data) {
      return true;
    } else {
      return false;
    }
  },
};
