<!-- ----------------------------------------------------------------------- -->
<!--
name    : SCHEDULE DIALOG

type    : component

used by : AppScheduleView
          AppWorkOrderView

uses    : simple-dialog-template
          header-view

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container grid-list-md class="schedule-dialog">
    <v-layout fill-height>
      <v-flex>
        <v-sheet height="64">
          <v-toolbar flat color="white">
            <v-btn outlined class="mr-4" @click="setToday"> Today </v-btn>
            <v-btn fab text small @click="prev">
              <v-icon class="display-1">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small @click="next">
              <v-icon class="display-1">mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-checkbox
              class="mr-3 mt-5"
              v-model="justThisWorkOrder"
              label="Current Work Order Only"
              v-if="editWorkOrder"></v-checkbox>
            <v-menu bottom right class="mr-3">
              <template v-slot:activator="{on}">
                <v-btn outlined v-on="on">
                  <span v-if="selectedForeman && selectedForeman.text">{{
                    selectedForeman.text
                  }}</span>
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="user in foremanList"
                  v-bind:key="user.value"
                  @click="selectedForeman = user">
                  <v-list-item-title v-if="user.text">{{
                    user.text
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu bottom right>
              <template v-slot:activator="{on}">
                <v-btn outlined v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="filteredDateObjects"
            :event-color="getEventColor"
            :event-margin-bottom="3"
            :now="today"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:day="viewDay"
            @click:interval="addEvent"
            @click:time="addEvent"
            @change="updateRange"
            :interval-format="timeFormat">
            <template v-slot:event="props">
              <span class="ml-1">{{ formatStartTime(props.event.start) }}</span>
              <span class="ml-1">{{ props.event.name }}</span>
            </template>
          </v-calendar>
          <v-dialog v-model="selectedOpen" width="500">
            <v-card color="grey lighten-4" min-width="350px" flat>
              <header-view
                dialog
                previous_page="NO_BACK_BUTTON"
                :title="selectedEvent.name"
                closeBtn
                :closeAction="closeDialog"
                :btnOptions="setHeaderButtons()"
                ref="headerView" />
              <v-card-text v-if="viewingEvent">
                <div v-if="selectedEvent.client_name" class="title">
                  Client: {{ selectedEvent.client_name }}
                </div>
                <div
                  class="subtitle-2"
                  v-if="selectedEvent.startTimeOriginalFormat">
                  <b>Start:</b>
                  {{
                    this.displayDateAndTimeFormat(
                      new Date(
                        selectedEvent.startTimeOriginalFormat.split('.')[0]
                      )
                    )
                  }}
                </div>
                <div
                  class="subtitle-2"
                  v-if="selectedEvent.endTimeOriginalFormat">
                  <b>End:</b>
                  {{
                    this.displayDateAndTimeFormat(
                      new Date(
                        selectedEvent.endTimeOriginalFormat.split('.')[0]
                      )
                    )
                  }}
                </div>
              </v-card-text>
              <v-card-text v-if="editingEvent">
                <span v-if="standardTimeFormat">
                  <v-layout>
                    <v-flex xs12> Start: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4 px-2>
                      <v-select
                        v-model="selectedStartHour"
                        :items="standardAvailableHours"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <div class="headline">:</div>
                    </v-flex>
                    <v-flex xs4 px-2>
                      <v-select
                        v-model="selectedStartMinute"
                        :items="availableEventMinutes"></v-select>
                    </v-flex>
                    <v-flex xs3 px-2>
                      <v-select
                        v-model="selectedStartAMPM"
                        :items="availableEventAMPM"></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12> End: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4 px-2>
                      <v-select
                        v-model="selectedEndHour"
                        :items="standardAvailableHours"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <div class="headline">:</div>
                    </v-flex>
                    <v-flex xs4 px-2>
                      <v-select
                        v-model="selectedEndMinute"
                        :items="availableEventMinutes"></v-select>
                    </v-flex>
                    <v-flex xs3 px-2>
                      <v-select
                        v-model="selectedEndAMPM"
                        :items="availableEventAMPM"></v-select>
                    </v-flex>
                  </v-layout>
                </span>
                <span v-else>
                  <v-layout>
                    <v-flex xs12> Start: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedStartHour"
                        :items="militaryAvailableHours"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <span class="headline">:</span>
                    </v-flex>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedStartMinute"
                        :items="availableEventMinutes"></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12> End: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedEndHour"
                        :items="militaryAvailableHours"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <div class="headline">:</div>
                    </v-flex>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedEndMinute"
                        :items="availableEventMinutes"></v-select>
                    </v-flex>
                  </v-layout>
                </span>
              </v-card-text>
              <v-card-actions v-if="viewingEvent">
                <v-btn
                  text
                  color="secondary"
                  :href="'/workorders/view/' + selectedEvent.workOrderUuid"
                  v-if="selectedEvent.workOrderUuid">
                  View Work Order
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="addWorkOrderEventOpen" width="500">
            <v-card color="grey lighten-4" min-width="350px" flat>
              <header-view
                dialog
                previous_page="NO_BACK_BUTTON"
                title="Schedule Work Time"
                closeBtn
                :closeAction="closeNewEventDialog"
                :btnOptions="[
                  {
                    name: '',
                    btnColor: 'white',
                    icon: 'mdi-check',
                    action: () => {
                      this.saveEvent(false);
                    },
                  },
                ]" />
              <v-card-text>
                <v-layout>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="selectedAllDay"
                      label="Full Day Job"
                      @change="selectedAllDayChanged"></v-checkbox>
                  </v-flex>
                </v-layout>
                <span v-if="standardTimeFormat">
                  <v-layout>
                    <v-flex xs12> Start: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4 px-3>
                      <v-select
                        v-model="selectedStartHour"
                        :items="standardAvailableHours"
                        @change="dayTimesChanged"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <div class="headline">:</div>
                    </v-flex>
                    <v-flex xs4 px-3>
                      <v-select
                        v-model="selectedStartMinute"
                        :items="availableEventMinutes"
                        @change="dayTimesChanged"></v-select>
                    </v-flex>
                    <v-flex xs3 px-3>
                      <v-select
                        v-model="selectedStartAMPM"
                        :items="availableEventAMPM"
                        @change="dayTimesChanged"></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12> End: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs4 px-3>
                      <v-select
                        v-model="selectedEndHour"
                        :items="standardAvailableHours"
                        @change="dayTimesChanged"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <div class="headline">:</div>
                    </v-flex>
                    <v-flex xs4 px-3>
                      <v-select
                        v-model="selectedEndMinute"
                        :items="availableEventMinutes"
                        @change="dayTimesChanged"></v-select>
                    </v-flex>
                    <v-flex xs3 px-3>
                      <v-select
                        v-model="selectedEndAMPM"
                        :items="availableEventAMPM"
                        @change="dayTimesChanged"></v-select>
                    </v-flex>
                  </v-layout>
                </span>
                <span v-else>
                  <v-layout>
                    <v-flex xs12> Start: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedStartHour"
                        :items="militaryAvailableHours"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <span class="headline">:</span>
                    </v-flex>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedStartMinute"
                        :items="availableEventMinutes"></v-select>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12> End: </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedEndHour"
                        :items="militaryAvailableHours"></v-select>
                    </v-flex>
                    <v-flex xs1 px-2 pt-4>
                      <div class="headline">:</div>
                    </v-flex>
                    <v-flex px-3>
                      <v-select
                        v-model="selectedEndMinute"
                        :items="availableEventMinutes"></v-select>
                    </v-flex>
                  </v-layout>
                </span>
              </v-card-text>
            </v-card>
          </v-dialog>

          <simple-dialog-template
            :open="pleaseCorrectTimeDialog"
            dialogTitle="Invalid Event Times"
            dialogText="The start time must be before the end time."
            dialogButtonOne="OK"
            @buttonOne="pleaseCorrectTimeDialog = false" />
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import HeaderView from '@/components/header-view';
  import dateTimeHelperFunctions from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';

  // services
  import Scheduling from '@/services/Scheduling.service.js';
  import Clients from '@/services/Clients.service.js';
  import Users from '@/services/Users.service.js';

  export default {
    name: 'ScheduleDialog',
    components: {
      'simple-dialog-template': SimpleDialogTemplate,
      'header-view': HeaderView,
    },
    mixins: [dateTimeHelperFunctions, Localization],
    props: {
      today: {
        type: String,
        default: null,
      },
      dialogFocus: {
        type: String,
        default: null,
      },
      dateObjects: {
        type: Array,
        default: null,
      },
      workorderEventIds: {
        type: Array,
        default: null,
      },
      canEditEvents: {
        type: Boolean,
        default: null,
      },
      editWorkOrder: {
        type: String,
        default: null,
      },
      userList: {
        type: Array,
        default: () => [],
      },
      selectedUserUUID: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        //these are the dates in the date picker before saving
        //start time and end time for new event
        selectedAllDay: false,
        eventIds: this.workorderEventIds,
        //filteredDateObjects : this.dateObjects,
        focus: this.dialogFocus,
        newEventStart: undefined,
        newEventEnd: undefined,
        type: 'month',
        typeToLabel: {
          month: 'Month',
          week: 'Week',
          day: 'Day',
          '4day': '4 Days',
        },
        start: null,
        end: null,
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        addWorkOrderEventOpen: false,
        standardAvailableHours: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
        ],
        militaryAvailableHours: [
          '00',
          '01',
          '02',
          '03',
          '04',
          '05',
          '06',
          '07',
          '08',
          '09',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
        ],
        availableEventMinutes: ['00', '15', '30', '45'],
        availableEventAMPM: ['AM', 'PM'],
        possibleEventColors: [
          '#2fa3f2', //redish
          '#388322', //greenish
          '#575C85', //purpleish
          '#6C160C', //redish
          '#DC2D18', //redish
          '#B55E54', //pinkish
          '#70D353', //greenish
          '#04FF00', //greenish
          '#00AAFF', //blueish
          '#005E8E', //blueish
          '#567585', //grayish
          '#855784', //purpleish
          '#D80775', //hotpinkish
          '#7C0041', //magentaish
          '#000000', //black
          '#7B1010', //marroon
          '#8CAF89', //palgreen
          '#53FF44', //lightgreen
          '#99A927', //yuckygreen
          '#51BEB9', //turqoise
          '#008790', //darkturqoise
          '#7B1010', //marroon
        ],
        selectedStartHour: '',
        selectedStartMinute: '',
        selectedStartAMPM: '',
        selectedEndHour: '',
        selectedEndMinute: '',
        selectedEndAMPM: '',
        selectedYear: '',
        viewingEvent: false,
        editingEvent: false,
        eventColorKey: {},
        foremanList: [],
        selectedForeman: undefined,
        editCurrentEvent: false,
        pleaseCorrectTimeDialog: false,
        justThisWorkOrder: false,
        tenantSettings: {},
      };
    },
    computed: {
      //async standardTimeFormat() {
      standardTimeFormat() {
        if (this.$auth.tenantProfile.time_format == 'standard') {
          console.log('Standard Time');
          return true;
        } else {
          console.log('Military Time');
          return false;
        }
      },
      title() {
        const {start, end} = this;
        if (!start || !end) {
          return '';
        }

        const startMonth = this.monthFormatter(start);
        const endMonth = this.monthFormatter(end);
        const suffixMonth = startMonth === endMonth ? '' : endMonth;

        const startYear = start.year;
        const endYear = end.year;
        const suffixYear = startYear === endYear ? '' : endYear;

        const startDay = start.day + this.nth(start.day);
        const endDay = end.day + this.nth(end.day);

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`;
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`;
        }
        return '';
      },
      monthFormatter() {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC',
          month: 'long',
        });
      },
      filteredDateObjects() {
        var finalObjects = [];

        if (this.dateObjects) {
          finalObjects = this.dateObjects.slice(0);
          finalObjects.forEach((date) => {
            if (date.foreman_uuid) {
              var foreman = this.foremanList.find((foreman) => {
                return foreman.value == date.foreman_uuid;
              });
              date.name = foreman ? foreman.text : 'Unassigned';
            } else {
              date.name = 'Unassigned';
            }
          });
        }
        if (this.justThisWorkOrder && this.editWorkOrder) {
          finalObjects = finalObjects.filter((date) => {
            return (
              date.workOrderUuid && this.editWorkOrder == date.workOrderUuid
            );
          });
        }
        if (this.selectedForeman) {
          finalObjects = finalObjects.filter((date) => {
            if (this.selectedForeman.value == 'unassigned') {
              // only return events with no foreman id
              return !date.foreman_uuid;
            } else if (this.selectedForeman.value == 'viewAll') {
              return true;
            } else {
              if (date.foreman_uuid) {
                return date.foreman_uuid == this.selectedForeman.value;
              } else {
                return false;
              }
            }
          });
        }
        if (finalObjects.length == 0) {
          finalObjects = [
            {
              start: '1900-01-01',
              name: '',
            },
          ];
        }

        return finalObjects;
      },
    },
    watch: {
      eventIds: async function () {
        // emit an event
        this.$emit('eventIdsUpdated', this.eventIds);
      },
      viewingEvent: function () {
        this.setHeaderButtons();
      },
      selectedUserUUID: function () {
        this.selectForeman();
      },
    },
    async mounted() {
      this.getForemanList();
    },
    methods: {
      formatStartTime(startTime) {
        return this.formatTime(
          new Date(startTime.split('.')[0]),
          this.$auth.tenantProfile.time_format
        );
      },

      async getForemanList() {
        if (this.userList && this.userList.length > 0) {
          // shallow copy array so unassigned and view all options can be added to the array
          this.foremanList = [...this.userList];
        } else {
          this.busy = true;
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          var usersResponse = await Users.getUsersByTenant(
            this.$auth.userProfile.tenant_uuid,
            accessToken
          );

          if (usersResponse) {
            this.foremanList = usersResponse
              .map((user) => {
                return {
                  text: user.name,
                  value: user.uuid,
                  active: user.status != 'Inactive',
                };
              })
              .filter((user) => user.active);
          }
        }

        this.foremanList.unshift({
          text: 'Unassigned',
          value: 'unassigned',
        });
        this.foremanList.unshift({
          text: 'View All',
          value: 'viewAll',
        });

        this.selectForeman();
        // console.log( "userList", this.userList )
        // console.log( "foremanList", this.foremanList )
        // console.log( "selectedForeman", this.selectedForeman )
      },
      selectForeman() {
        if (this.selectedUserUUID) {
          for (var user of this.foremanList) {
            if (user.value === this.selectedUserUUID) {
              this.selectedForeman = user;
            }
          }
        } else if (this.foremanList.length > 0) {
          this.selectedForeman = this.foremanList[0];
        }
      },
      setHeaderButtons() {
        var btns = [];

        if (this.viewingEvent) {
          btns = [
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-pencil',
              action: this.editEvent,
            },
          ];
        } else if (this.editingEvent) {
          btns = [
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-content-save',
              action: () => {
                this.saveEvent(this.selectedEvent);
              },
            },
            {
              name: '',
              btnColor: 'white',
              icon: 'mdi-delete',
              action: () => {
                this.deleteEvent(this.selectedEvent);
              },
            },
          ];
        }

        // if ( this.$refs.headerView ) {
        //   this.$refs.headerView.btnOptions = btns;
        // }

        return btns;
      },
      closeDialog() {
        this.selectedOpen = false;
        this.editingEvent = false;
        this.viewingEvent = true;
        this.selectedAllDay = false;
      },
      closeNewEventDialog() {
        this.addWorkOrderEventOpen = false;
        this.selectedAllDay = false;
      },
      addZero(value) {
        if (value < 10) {
          value = '0' + value;
        }
        return value;
      },
      editEvent: function () {
        this.selectedYear = new Date(
          this.selectedEvent.startTimeOriginalFormat.split('.')[0]
        )
          .getFullYear()
          .toString();
        this.selectedMonth = (
          new Date(
            this.selectedEvent.startTimeOriginalFormat.split('.')[0]
          ).getMonth() + 1
        ).toString();
        this.selectedDate = new Date(
          this.selectedEvent.startTimeOriginalFormat.split('.')[0]
        )
          .getDate()
          .toString();

        var startHours = new Date(
          this.selectedEvent.startTimeOriginalFormat.split('.')[0]
        ).getHours();
        var startMinutes = new Date(
          this.selectedEvent.startTimeOriginalFormat.split('.')[0]
        ).getMinutes();
        var endHours = new Date(
          this.selectedEvent.endTimeOriginalFormat.split('.')[0]
        ).getHours();
        var endMinutes = new Date(
          this.selectedEvent.endTimeOriginalFormat.split('.')[0]
        ).getMinutes();

        if (this.$auth.tenantProfile.time_format == 'standard') {
          if (startHours > 12) {
            this.selectedStartHour = startHours % 12;
          } else {
            this.selectedStartHour = startHours;
          }
          if (startHours > 11) {
            this.selectedStartAMPM = 'PM';
          } else {
            this.selectedStartAMPM = 'AM';
          }

          if (endHours > 12) {
            this.selectedEndHour = endHours % 12;
          } else {
            this.selectedEndHour = endHours;
          }
          if (endHours > 11) {
            this.selectedEndAMPM = 'PM';
          } else {
            this.selectedEndAMPM = 'AM';
          }
        } else {
          this.selectedStartHour = this.addZero(startHours);
          this.selectedEndHour = this.addZero(endHours);
          this.selectedStartAMPM = '';
          this.selectedEndAMPM = '';
        }

        this.selectedStartHour = this.selectedStartHour.toString();
        this.selectedEndHour = this.selectedEndHour.toString();
        this.selectedStartMinute = this.addZero(startMinutes).toString();
        this.selectedEndMinute = this.addZero(endMinutes).toString();

        this.viewingEvent = false;
        this.editingEvent = true;
      },
      doneEditingEvent: function () {
        this.viewingEvent = true;
        this.editingEvent = false;
      },
      toCalendarFriendlyString: function (date) {
        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes()
        );
      },
      displayDateAndTimeFormat: function (date) {
        return (
          this.formatTime(date, this.$auth.tenantProfile.time_format) +
          ', ' +
          this.displayDateFormat(date, this.$auth.tenantProfile.date_format)
        );
      },
      viewDay({date}) {
        this.focus = date;
        this.type = 'day';
      },
      getEventColor(event) {
        if (!this.eventColorKey[event.foreman_uuid]) {
          this.eventColorKey[event.foreman_uuid] =
            this.possibleEventColors[Object.keys(this.eventColorKey).length];
        }
        return this.eventColorKey[event.foreman_uuid] || '#2fa3f2';
      },
      setToday() {
        this.focus = this.today;
      },
      prev() {
        this.$refs.calendar.prev();
      },
      next() {
        this.$refs.calendar.next();
      },
      async showEvent({nativeEvent, event}) {
        if (!event.client_name) {
          // Get the access token from the auth wrapper
          const accessToken = await this.$auth.getTokenSilently();

          var res = await Clients.getClient(event.clientUuid, accessToken);

          if (res) {
            event.client_name = res.client_name;
            this.viewingEvent = true;
            const open = () => {
              this.selectedEvent = event;
              this.selectedElement = nativeEvent.target;
              setTimeout(() => (this.selectedOpen = true), 10);
            };

            if (this.selectedOpen) {
              this.selectedOpen = false;
              setTimeout(open, 10);
            } else {
              open();
            }
          }
        } else {
          this.viewingEvent = true;
          const open = () => {
            this.selectedEvent = event;
            this.selectedElement = nativeEvent.target;
            setTimeout(() => (this.selectedOpen = true), 10);
          };

          if (this.selectedOpen) {
            this.selectedOpen = false;
            setTimeout(open, 10);
          } else {
            open();
          }
        }

        nativeEvent.stopPropagation();
      },
      addEvent(args) {
        if (this.canEditEvents) {
          if (this.$auth.tenantProfile.time_format == 'standard') {
            // have to break it into cases because otherwise 12 will mod to 0
            if (args.hour > 12) {
              this.selectedStartHour = (args.hour % 12).toString();
            } else {
              this.selectedStartHour = args.hour.toString();
            }
            if (args.hour >= 12) {
              this.selectedStartAMPM = 'PM';
            } else {
              this.selectedStartAMPM = 'AM';
            }
            if (args.hour >= 12) {
              this.selectedEndHour = ((args.hour % 12) + 1).toString();
              this.selectedEndAMPM = 'PM';
            } else if (args.hour == 11) {
              this.selectedEndHour = '12';
              this.selectedEndAMPM = 'PM';
            } else {
              this.selectedEndHour = (args.hour + 1).toString();
              this.selectedEndAMPM = 'AM';
            }
          } else {
            this.selectedStartHour = this.addZero(args.hour).toString();
            this.selectedEndHour = this.addZero(args.hour + 1).toString();
            this.selectedStartAMPM = '';
            this.selectedEndAMPM = '';
          }

          this.selectedYear = args.year;
          this.selectedMonth = args.month;
          this.selectedDate = args.day;
          this.selectedStartMinute = '00';
          this.selectedEndMinute = '00';

          const open = () => {
            //this.selectedEvent = event
            setTimeout(() => (this.addWorkOrderEventOpen = true), 10);
          };

          if (this.addWorkOrderEventOpen) {
            this.addWorkOrderEventOpen = false;
            setTimeout(open, 10);
          } else {
            open();
          }
        }
        //nativeEvent.stopPropagation()
      },
      getMilitaryTime: function (hour, minute, ampm) {
        if (ampm == 'AM' || ampm == '' || parseInt(hour) == 12) {
          return hour + ':' + minute;
        } else {
          return parseInt(hour) + 12 + ':' + minute;
        }
      },
      async deleteEvent(existingEvent) {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        var res = await Scheduling.deleteWorkOrderEvent(
          existingEvent.eventUuid,
          accessToken
        );

        if (res) {
          this.eventIds.forEach((eventId, index) => {
            if (eventId == existingEvent.eventUuid) {
              this.eventIds.splice(index, 1);
            }
          });
          this.selectedOpen = false;
          this.addWorkOrderEventOpen = false;
          this.type = 'month';
          this.editingEvent = false;
          this.viewingEvent = true;
          // this.calendarDialog = false
        } else {
          throw Error('Something went wrong');
        }
      },
      async saveEvent(existingEvent) {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        var startTime = this.getMilitaryTime(
          this.selectedStartHour,
          this.selectedStartMinute,
          this.selectedStartAMPM
        );
        var endTime = this.getMilitaryTime(
          this.selectedEndHour,
          this.selectedEndMinute,
          this.selectedEndAMPM
        );

        this.newEventStart =
          this.selectedYear +
          '-' +
          this.selectedMonth +
          '-' +
          this.selectedDate +
          ' ' +
          startTime;
        this.newEventEnd =
          this.selectedYear +
          '-' +
          this.selectedMonth +
          '-' +
          this.selectedDate +
          ' ' +
          endTime;

        // both start and end are formatted like this: "2019-8-15 13:00"
        //need to compare to see if the start is after the end
        if (new Date(this.newEventEnd) < new Date(this.newEventStart)) {
          this.pleaseCorrectTimeDialog = true;
        } else {
          if (existingEvent) {
            const data = {
              start_time: this.newEventStart,
              end_time: this.newEventEnd,
              foreman_uuid: this.selectedUserUUID,
            };

            var res_update = await Scheduling.updateEvent(
              existingEvent.eventUuid,
              data,
              accessToken
            );
            if (res_update) {
              this.selectedEvent.start = this.toCalendarFriendlyString(
                new Date(this.newEventStart.split('.')[0])
              );
              this.selectedEvent.end = this.toCalendarFriendlyString(
                new Date(this.newEventEnd.split('.')[0])
              );
              this.selectedEvent.startTimeOriginalFormat = this.newEventStart;
              this.selectedEvent.endTimeOriginalFormat = this.newEventEnd;
              this.viewingEvent = true;
              this.editingEvent = false;
              this.selectedAllDay = false;
            }
          } else {
            // create new event, and add it to the work orders

            const data = {
              workOrderDates: [[this.newEventStart, this.newEventEnd]],
              tenant_uuid: this.$auth.userProfile.tenant_uuid,
              foreman_uuid: this.selectedUserUUID,
            };

            var res_create = await Scheduling.createEvent(data, accessToken);

            if (res_create) {
              if (this.eventIds) {
                this.eventIds = this.eventIds.concat(res_create);
              } else {
                this.eventIds = res_create;
              }
              this.addWorkOrderEventOpen = false;
              this.type = 'month';
              this.selectedAllDay = false;
              this.editingEvent = false;
              this.viewingEvent = true;
            } else {
              throw Error('Something went wrong');
            }
          }
        }
      },
      updateRange({start, end}) {
        // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
        this.start = start;
        this.end = end;
      },
      nth(d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][
              d % 10
            ];
      },
      saveCalendarDates: function () {
        this.calendarDialog = false;
        this.snackbar = true;
      },
      selectedAllDayChanged: function () {
        if (this.selectedAllDay) {
          if (this.standardTimeFormat) {
            this.selectedStartHour = '7';
            this.selectedStartMinute = '00';
            this.selectedStartAMPM = 'AM';
            this.selectedEndHour = '3';
            this.selectedEndMinute = '30';
            this.selectedEndAMPM = 'PM';
          } else {
            this.selectedStartHour = '07';
            this.selectedStartMinute = '00';
            this.selectedEndHour = '15';
            this.selectedEndMinute = '30';
          }
        }
      },
      dayTimesChanged: function () {
        if (
          this.selectedStartHour == '7' &&
          this.selectedStartMinute == '00' &&
          this.selectedStartAMPM == 'AM' &&
          this.selectedEndHour == '3' &&
          this.selectedEndMinute == '30' &&
          this.selectedEndAMPM == 'PM'
        ) {
          this.selectedAllDay = true;
        } else {
          this.selectedAllDay = false;
        }
      },
      timeFormat(interval) {
        var time;

        switch (this.$auth.tenantProfile.time_format) {
          case 'standard':
            var hour = interval.hour % 12;
            hour = hour == 0 ? 12 : hour;
            time = hour + ' ' + (interval.hour >= 12 ? 'PM' : 'AM');
            break;
          case 'military':
            time =
              this.addLeadingZero(interval.hour) +
              '' +
              this.addLeadingZero(interval.minute);
            break;
          default:
            time = '';
        }

        return time;
      },
    },
  };
</script>

<style scoped lang="scss">
  .headline {
    text-align: center;
  }
</style>
