<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP SCHEDULE VIEW

type    : view

uses    : header-list
          main-container
          schedule-dialog

route   : /scheduling
 -->
<!-- ----------------------------------------------------------------------- -->

<template>
  <div>
    <header-list title="Schedule" />
    <main-container>
      <v-layout>
        <schedule-dialog
          v-bind:today="this.today"
          v-bind:dialogFocus="this.focus"
          v-bind:dateObjects="this.calendarDates" />
      </v-layout>
    </main-container>
  </div>
</template>

<script>
  import axios from 'axios';

  import ScheduleDialog from '@/components/schedule/schedule-dialog';
  import HeaderList from '@/components/header-list';
  import MainContainer from '@/components/main-container';
  import Localization from '@/mixins/localization';

  export default {
    name: 'ScheduleView',
    components: {
      'schedule-dialog': ScheduleDialog,
      'header-list': HeaderList,
      'main-container': MainContainer,
    },
    mixins: [Localization],
    data: () => ({
      crew: {},
      foreman_name: '',
      events: [],
      today: undefined,
      focus: undefined,
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      start: null,
      end: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      addWorkOrderEventOpen: false,
      selectedInterval: null,
      workorders: [],
      calendarDates: [],
    }),
    computed: {},
    async mounted() {
      this.today = this.toCalendarFriendlyString(new Date());
      this.focus = this.toCalendarFriendlyString(new Date());

      let params = {};
      params['tenant_uuid'] = this.$auth.userProfile.tenant_uuid;
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // console.log('sheduling: loading events');
      axios({
        method: 'GET',
        url: '/service_scheduling/events/all',
        params: {
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
          start_time: '2019-02-10',
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((eventResults) => {
          axios({
            method: 'GET',
            url: '/service_jobs/work_orders/all',
            params: params,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((workOrderResponse) => {
              this.workorders = workOrderResponse.data;

              this.workOrderDates = eventResults.data.map((event) => {
                var eventToAddToCalendar = {
                  name: 'Work Order',
                  start: this.toCalendarFriendlyString(
                    new Date(event.start_time.split('.')[0])
                  ),
                  end: this.toCalendarFriendlyString(
                    new Date(event.end_time.split('.')[0])
                  ),
                  formattedStartTime: this.formatTime(
                    new Date(event.start_time.split('.')[0]),
                    this.$auth.tenantProfile.time_format
                  ),
                  details: 'heres details',
                  color: 'blue',
                  eventUuid: event.uuid,
                  startTimeOriginalFormat: event.start_time,
                  endTimeOriginalFormat: event.end_time,
                };

                this.workorders.forEach((workOrder) => {
                  if (workOrder.event_uuids) {
                    workOrder.event_uuids.forEach((event_uuid) => {
                      // each event id should only be in one work order's list of ids. this is just to detect which work order has that event in it's list of event id's
                      if (event_uuid == event.uuid) {
                        eventToAddToCalendar.clientUuid = workOrder.client_uuid;
                        eventToAddToCalendar.workOrderUuid = workOrder.uuid;

                        if (workOrder.user_uuid) {
                          eventToAddToCalendar.foreman_uuid =
                            workOrder.user_uuid;
                        }
                      }
                    });
                  }
                });
                return eventToAddToCalendar;
              });

              this.calendarDates = this.workOrderDates;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    methods: {
      toCalendarFriendlyString: function (date) {
        return (
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          ':' +
          date.getMinutes()
        );
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }
  .v-calendar {
    border-top: 1px solid lightgray;
  }
</style>
